

























































import { Mixins, Component } from "vue-property-decorator";
import AppMixin from "@/mixins/App";
import SectionPage from "@/components/common/SectionPage.vue";
import SectionContent from "@/components/common/SectionContent.vue";
import ContactForm from "@/modules/contact/components/ContactForm.vue";
import Article from "@/models/Article";

@Component({
  components: {
    SectionPage,
    SectionContent,
    ContactForm
  }
})
export default class ContactIndex extends Mixins(AppMixin) {
  mounted() {
    this.load();
  }

  async load() {
    if (!this.obCategory || this.loading) {
      return;
    }

    this.loading = true;
    await this.articles
      .category(this.obCategory.id)
      .limit(3)
      .fetch();

    if (!this.article) {
      this.article = new Article();
    }

    this.article.set("slug", "contact-form");
    const response = await this.article.fetch();
    if (response) {
      const obData = response.getData();
      this.article.assign(obData.data || obData);
    }

    this.loading = false;
  }

  get obCategory() {
    return this.category("contact");
  }

  get articleStyle() {
    return this.article
      ? {
          "background-image": `url("${this.article.preview_image}")`
        }
      : {};
  }
}
